'use strict';

export default class Loading extends React.Component {
    render() {
        const {active, text = null, absolute = true} = this.props;
        const className = text !== null || absolute === false ? 'preloader' : 'loaderContainer';
        if(!active) return null;
        return <div className={className}>
            <div className="loader"></div>
            {text !== null && <span className="loader-text">
                {text}
            </span>}
        </div>
    }
}
